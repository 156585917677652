import UserInfo from '@/components/UserInfo.vue'
import { User } from '@/models/user'

export interface ContentList {
    createTime: string,
    date: string,
    id: number,
    isGrade: boolean,
    materialId: number,
    orderNum: number,
    planId: number,
    practiceNum: number
}

export interface SurveyList {
    createTime: string
    endTime: string
    id: number
    planId: number
    startTime: string
    surveyId: number
}

export enum ApplyStatus {
    AUDITING = 'AUDITING', AUDIT_PASSED = 'AUDIT_PASSED', AUDIT_FAILED = 'AUDIT_FAILED'
}

export enum PlanStatus {
    NOT_STARTED = 'NOT_STARTED', IN_PROGRESS = 'IN_PROGRESS', FINISHED = 'FINISHED'
}

export enum TaskType {
    PLAN_PRACTICE_TASK = 'PLAN_PRACTICE_TASK', DAILY_PRACTICE_TASK = 'DAILY_PRACTICE_TASK', SURVEY_TASK = 'PLAN_SURVEY_TASK'
}

export enum TaskPracticeType {
    STANDARD_EXERCISE = 'STANDARD_EXERCISE', LIFE_PRACTICE = 'LIFE_PRACTICE'
}

export interface Task {
    cover: string
    id: number
    isCompleted: boolean
    isGrade: boolean
    isStandard: boolean
    name: string
    planId: number
    practiceType: TaskPracticeType
    relatedId: number
    taskDate: string
    type: TaskType
    userId: number
}

export interface Journey {
    cover: string,
    dayNum: number,
    endTime: string,
    id: number,
    introduction: string,
    isAllowRetrain: true,
    name: string,
    participantNum: number,
    publishStatus: string,
    startTime: string,
    status: PlanStatus
}

export interface Dimension {
    createTime: string,
    createdBy: number,
    id: number,
    name: string,
    surveyId: number
}

export interface Question {
    content: any[],
    createTime: string,
    createdBy: number,
    id: number,
    required: boolean,
    sortNumber: number,
    surveyDimension: Dimension[]
    surveyDimensionId: number,
    surveyId: number,
    title: string,
    type: string
    answer: number
}

export interface Survey {
    cover: string,
    createTime: string,
    createdBy: number,
    dimensions: Dimension[]
    id: number,
    introduction: string,
    name: string,
    questionNum: number,
    questions: Question[]
    title: string
}

export interface PlanUser {
    createTime: string
    createdBy: number
    id: number
    mobilePhone: string
    planId: number
    status: ApplyStatus
    studentId: number
    studentName: string
}

export interface PlanStatics {
    lifePracticeTotalDays: number
    lifePracticeTotalNum: number
    standardExerciseTotalDays: number
    standardExerciseTotalDurationMin: number
    standardExerciseTotalNum: number
    user: User
    userId: number
}

export interface DatePracticeInfo {
    id: number
    isGrade: boolean
    materialCover: string
    materialId: number
    materialIntroduction: string
    materialName: string
    orderNum: number
    planId: number
    practiceNum: number
    materialType: string
}

export interface DatePractice {
    date: string
    practiceList: DatePracticeInfo[]
}

export interface Plan {
    cover: string
    createTime: string
    endTime: string
    id: number
    introduction: string
    isAllowRetrain: boolean
    name: string,
    planContentList: ContentList[]
    planSurveyList: SurveyList[]
    publishStatus: string,
    startTime: string,
    status?: PlanStatus
    applyStatus?: ApplyStatus
    applyNum?: number
    planContentGroupByDateList?: DatePractice[]
    isNeedAudit?: boolean
}
