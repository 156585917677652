
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { PlayList } from '@/models/practice'
import dayjs from 'dayjs'
import { DatePracticeInfo } from '../models/plan'

@Options({
  components: {}
})
export default class PlanTeacherDetailPracticeCard extends Vue {
  @Prop() item!:DatePracticeInfo

  getDate (date: any) {
    return dayjs(date).format('M月D日')
  }
}
